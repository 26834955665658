/// <reference path='../../../../../node_modules/@types/jquery/index.d.ts' />
/// <reference path='../../../Cider.Catalog/Assets/TypeScript/conference-session.ts' />
/// <reference path='../../../Cider.Framework/Assets/TypeScript/notifier.ts' />

$(document).ready(() => {
    AddToCart.initialize();
});

class AddToCart {
    public static initialize() {
        this.loadPendingAddToCartDisplays();
        $(document).on('cider.core.productsLoaded', () => {
            this.loadPendingAddToCartDisplays();
        });
    }

    private static loadPendingAddToCartDisplays() {
        //includes loading add to cart

        $('.add-to-cart-location').not('.listening').each((_index: number, element: Element) => {
            let addToCartLocation: JQuery = $(element);
            let ajaxUrl: string = addToCartLocation.data('request-url');
            this.load(addToCartLocation, ajaxUrl);
        }).addClass('listening');
    }

    private static load(addToCartLocation: JQuery, ajaxUrl: string) {
        $.ajax({
            url: ajaxUrl,
            success: (html: string) => {
                addToCartLocation.html(html);
                addToCartLocation.find('.add-to-cart-container').each((_e: number, element: Element) => {
                    let addToCartContainer: JQuery = $(element);
                    this.initAddToCartContainer(addToCartContainer);
                });
            }
        });
    }

    public static initAddToCartContainer(addToCartContainer: JQuery) {
        addToCartContainer.find('form').not('.listening')
            .on('submit', (e: Event) => {
                let form: JQuery = $(e.currentTarget);
                this.add(form, e);                
            })
            .addClass('listening');

        addToCartContainer.find('select.add-to-cart-promotion-choice').not('.listening')
            .change((e: Event) => this.onChangePromotionChoice($(e.currentTarget)))
            .addClass('listening')
            .change();

        addToCartContainer.find('select.add-to-cart-attribute-choice.main-display').not('.listening')
            .change((e: Event) => this.onChangeAttributeChoiceMain($(e.currentTarget)))
            .addClass('listening');

        addToCartContainer.find('select.add-to-cart-attribute-choice.summary-display').not('.listening')
            .change((e: Event) => this.onChangeAttributeChoiceSummary($(e.currentTarget)))
            .addClass('listening');

        addToCartContainer.find('button.btn-donate-amount,button.btn-donate-preset').not('.listening')
            .click((e: JQueryEventObject) => this.onClickDonateButton($(e.currentTarget)))
            .addClass('listening');

        $(document).trigger('cider.shoppingCart.addToCartInitialized', addToCartContainer);
    }

    private static onClickDonateButton(button: JQuery) {
        let unitPriceInput: JQuery = $('input#unitPrice');
        let unitPrice: number = undefined;
        if (button.is('.btn-donate-preset')) {
            unitPrice = button.data('unit-price');
        }
        else {
            let donationAmountInput: JQuery = $('.donation-amount-field input.donate-amount');
            unitPrice = donationAmountInput.val();
        }

        if (!unitPrice) {
            unitPrice = 1; // Match default value from DonationAddToCart.cshtml
        }

        unitPriceInput.val(unitPrice);
        button.parents('form').trigger('submit');
    }

    public static add(form: JQuery, e: Event) {
        let isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        let popUpMiniCart = form.data('add-to-cart-behavior') === 'PopUpMiniCart';
        let isMiniCartAvailable = $.trim($('.shopping-cart-button').html()).length > 0;
        let isOnShoppingCartPage: boolean = $('.shopping-cart-page').length > 0;
        if (popUpMiniCart && isMiniCartAvailable && !isMobile && !isOnShoppingCartPage) {
            e.preventDefault();
            this.ajaxSubmit(form);
        }
        // Otherwise, do the default; submit the form.
    }

    private static ajaxSubmit(form: JQuery) {
        $('.shopping-cart-button').addClass('open');
        $('.mini-cart .spinner').addClass('csspinner ringed');
        $.post(form.attr('action'), form.serialize())
            .done((html) => {
                $('.shopping-cart-button').trigger('added-to-cart');
                $(document).trigger('cider.shoppingCart.cartUpdated', html);
            })
            .fail(() => {              
                this.addAjaxSubmitFailureNotification(form);

                $('.spinner').removeClass('csspinner ringed');
                $('.shopping-cart-button').removeClass('open');
                $('.shopping-cart-button').children('.dropdown-bar').removeClass('open');
            });

        return false;
    }

    private static addAjaxSubmitFailureNotification(form: JQuery): void {
        let zoneMessages: JQuery = $('.zone-messages');
        let zoneBeforeContent: JQuery = $('.zone-before-content');
        let zoneContent: JQuery = $('.zone-content');        
        let notification: JQuery = Notifier.error(`<strong>${form.data('error-label')}</strong>&nbsp;${form.data('error-message')}`);
        notification.addClass('add-to-cart-error');
        let notificationTarget: JQuery = null;

        if (zoneMessages.length) {
            notificationTarget = zoneMessages;
        }
        else if (zoneBeforeContent.length) {
            notificationTarget = zoneBeforeContent;
        }
        else if (zoneContent.length) {
            notificationTarget = zoneContent;
        }

        if (notificationTarget) {
            notificationTarget.find('.add-to-cart-error').remove();
            notificationTarget.prepend(notification);
        }
    }

    private static onChangePromotionChoice(dropdown: JQuery) {
        let addToCartContainer = dropdown.parents('.add-to-cart-container');
        let selectedChoice = dropdown.find(':selected');
        let choiceData = selectedChoice.data();

        addToCartContainer.find('.item-list-price').text(choiceData.listPrice);
        addToCartContainer.find('.item-price-label').text(choiceData.listPrice ? choiceData.discountPriceLabel : '');
        addToCartContainer.find('.item-list-price-label').text(choiceData.listPrice ? choiceData.listPriceLabel : '');
        addToCartContainer.find('.item-price').text(choiceData.price);
        addToCartContainer.find('.item-narrative').html(choiceData.narrative);
        addToCartContainer.find('.item-savings small').text(choiceData.savingsText);
        addToCartContainer.find('.add-to-cart-promote-discount').text(choiceData.discountDescription);     

        if (dropdown.hasClass('main-display')) {
            this.onChangeConferencePromotionChoice(dropdown);
        }
    }

    // When attribute choice is changed on the product detail page
    private static onChangeAttributeChoiceMain(dropdown: JQuery) {
        let productLink = dropdown.find(':selected').data('product-page-url');

        if (productLink != null) {
            let form: JQuery = dropdown.parent('form');
            form.find('button[type="submit"]').prop('disabled', true);
            form.addClass('csspinner ringed');

            // Using replace method here prevents an entry from being added to the navigation history, so back button won't make user
            // go back through every series of attributes they've selected for this product
            window.location.replace(productLink);
        }
    }

    // When attribute choice is changed on the search results page
    private static onChangeAttributeChoiceSummary(dropdown: JQuery) {
        let choice = dropdown.find(':selected').data();
        let addToCartUrl = choice.addToCartUrl;

        // Blank choice, don't do anything
        if (addToCartUrl == null) {
            return;
        }

        dropdown.closest('.add-to-cart-container').addClass('csspinner ringed');

        // Reload product summary display for selected product
        let productSummary = dropdown.closest('.content-item').find('.catalog-product-summary');
        this.reloadProductSummary(productSummary, choice.summaryUrl);

        // Reload add to cart block for selected product
        let addToCart = dropdown.closest('.add-to-cart-location');
        this.load(addToCart, addToCartUrl);
    }

    private static reloadProductSummary(productSummaryElement: JQuery, ajaxUrl: string) {
        $.ajax({
            url: ajaxUrl,
            success: (html: string) => {
                productSummaryElement.replaceWith(html);
            }
        });
    }

    private static onChangeConferencePromotionChoice(dropdown: JQuery) {
        let selectedChoice = dropdown.find(':selected').data();
        let conference = $('.conference-container');
        let sessionList = selectedChoice.conferenceSessions;

        if (sessionList === null) {
            // No conference data, so return
            return;
        }

        conference.find('.conference-location-name').text(selectedChoice.conferenceLocationDescription);
        conference.find('.conference-location-dates').text(selectedChoice.conferenceLocationStarts + ' - ' +
            selectedChoice.conferenceLocationEnds);

        conference.find('.session-time').each((_index: number, element: Element) => {
            let sessionTime = $(element);
            let sessionCode = sessionTime.data('session-code');
            let sessionCodeId = sessionTime.data('session-code-id');
            let conferenceDay = sessionTime.data('conference-day');
            let sessionStarts = sessionTime.data('session-starts');
            let sessionEnds = sessionTime.data('session-ends');
            let sessionSchedule = getSessionScheduleDescription(selectedChoice.conferenceLocationStarts, conferenceDay,
                sessionStarts, sessionEnds);
            conference.find(`#session-date-${sessionCodeId}`).text(sessionSchedule);

            if (sessionList.indexOf(sessionCode) !== -1) {
                sessionTime.parents('.panel').addClass('complete');
            }
            else {
                sessionTime.parents('.panel').removeClass('complete');
            }
        });
    }
}
